<template>
  <t-collapse :visible="visible"
                    @update:visible="visible = $event"
                    :header-line="false"
                    heading="w-75 font-weight-normal forum-topic-collapsible-card"
                    class="border p-4 my-4 rounded"
                    :class="{ 'border-info': visible }"
                    header-flex-alignment="align-items-baseline">
    <template #header>
    <div class="flex-grow-1" style="font-size: 12px;">

<!--      header-->

      <div class="mb-2 d-flex align-items-baseline">
        <span class="mr-5">{{formatTime(topic.createdOn)}} by {{topic.userName}}</span>

<!--        reply & vote count-->

        <h5 :class="replyCount > 0 ? 'text-muted' : 'text-danger'" class="mr-2 mb-0 font-weight-bold">
          {{replyCount}}
        </h5>
        <span class="mr-5">
          {{replyCount === 1 ? ' Reply' : ' Replies'}}
        </span>
        <h5 class="mr-2 mb-0 font-weight-bold text-muted">
          {{voteCount}}
        </h5>
        <span class="mr-5">
          {{voteCount === 1 ? ' Vote' : ' Votes'}}
        </span>

<!--        requires assistance ? -->

        <template v-if="topic.requiresDevAssistance">
          <fluency-icon type="bug" class="text-warning mr-2"></fluency-icon>
          <div class="mr-5">
            <span>Requires Dev Assistance</span>
            <div v-if="assigned">Assigned to {{assigned.userName}}</div>
          </div>
        </template>
        <template v-else-if="topic.requiresFluencyAssistance">
          <fluency-icon type="warning" class="text-warning mr-2"></fluency-icon>
          <div class="mr-5">
            <div>Requires Fluency Assistance</div>
            <div v-if="assigned">Assigned to {{assigned.userName}}</div>
          </div>
        </template>
        <div v-else-if="assigned" class="mr-5">Assigned to {{assigned.userName}}</div>
        <template v-if="topic.replies && topic.replies.some(r => r.acceptedAnswer)">
          <fluency-icon type="checkmark" class="text-success mr-2"></fluency-icon>
          <span>Answered</span>
        </template>

<!--        options & badges -->

        <div class="ml-5 d-flex align-items-center">
          <div v-if="subscribed"
               class="bg-info d-flex align-items-center justify-content-center rounded mr-4"
               v-p-tooltip.top="'Subscribed'"
               style="height:25px; width: 25px">
            <fluency-icon type="envelopeClosed" class="text-white"></fluency-icon>
          </div>
          <div v-if="assignedToCurrentUser"
               class="bg-info d-flex align-items-center justify-content-center rounded mr-4"
               v-p-tooltip.top="'Assigned To You'"
               style="height:25px; width: 25px">
            <fluency-icon :type="'star'" class="text-white"></fluency-icon>
          </div>
          <span class="text-muted mr-4">{{topic.channel}}</span>
          <forum-topic-options :topic="topic" :subscribed="!!subscribed" :assigned-to-current-user="!!assignedToCurrentUser"></forum-topic-options>
        </div>
      </div>

<!--      title, tags & message preview -->

      <div class="d-flex align-items-center flex-wrap mb-1">
        <div v-if="topic.title" class="font-weight-bold" style="font-size: 13px;">{{$filters.truncate(topic.title, 100)}}</div>
        <span v-if="topic.severity" class="ml-3" :class="`text-${severityMap[topic.severity]}`">{{topic.severity}}</span>
        <span v-if="topic.impactingDelivery" class="ml-3 text-danger" >Impacting Delivery</span>
      </div>
      <div v-if="topic.tagsJson" class="d-flex align-items-center flex-wrap mt-2">
        <p-chip v-for="(tag, index) in topic.tagsJson" :key="tag" class="d-flex align-items-center mr-2 mb-2" :class="{'ml-2': index !== 0}">{{tag}}</p-chip>
      </div>
      <div v-if="!visible">
        {{$filters.removeMarkdown($filters.truncate(topic.message, 300))}}
      </div>

<!--      end header-->
    </div>
    </template>
<!--    message & voting-->

    <div class="p-5 d-flex align-items-center">
      <forum-votes :item="topic"></forum-votes>
      <div class="pl-5 flex-grow-1 word-break-break-word" v-markdown="topic.message"></div>
    </div>

    <div class="px-5">

<!--      reply count and controls-->

      <div class="d-flex align-items-baseline mb-3">
        <h5 class="mb-0 mr-3">{{replyCount}} {{replyCount === 1 ? 'Reply' : 'Replies'}}</h5>
        <template v-if="replyCount > 0">
          <span class="mr-3">Sorted By</span>
          <mad-lib-select v-model="sortBy" :options="sortByOptions" :formatter="$filters.convertFromCamelCase"></mad-lib-select>
        </template>
        <t-button v-if="replying" class="ml-auto" @click="replying = false">Cancel</t-button>
        <t-button v-else variant="primary" class="ml-auto" @click="replying = true">Reply</t-button>
      </div>

<!--      reply input -->

      <div v-if="replying" class="border-top py-5">
        <forum-content-input :message="newReplyMessage" @update:message="newReplyMessage = $event" />
        <div class="d-flex justify-content-end">
          <t-button variant="primary" :disabled="!newReplyMessage" @click="submitReply()">Submit</t-button>
        </div>
      </div>

<!--      replies-->
      <div v-for="reply in sortedReplies" :key="reply.forumTopicReplyId" class="d-flex align-items-center border-top py-5">
        <forum-votes :item="reply" class="ml-3"></forum-votes>
        <div class="pl-5 flex-grow-1">
          <div class="d-flex mb-3">
            <div>{{reply.userName}}</div>
            <div class="ml-auto">{{formatTimeMinutes(reply.createdOn)}}</div>
            <t-button-inline v-if="showDeleteReply(reply)" severity="secondary" class="ml-3" v-p-tooltip.top="'Delete this Reply'" @click="deleteReply(reply)">
              <fluency-icon type="delete"></fluency-icon>
            </t-button-inline>
          </div>
          <div v-markdown="reply.message">
          </div>
          <div class="d-flex align-items-center mt-3">
            <t-button-inline v-if="showAcceptLink" @click="acceptReply(reply)">Accept This Reply As The Correct Answer</t-button-inline>
            <template v-else-if="reply.acceptedAnswer">
              <h3 class="mr-3 mb-0">
                <fluency-icon type="checkmark" class="text-success"></fluency-icon>
              </h3>
              <span>This reply has been accepted as the correct answer.</span>
              <t-button-inline v-if="internalUser" class="ml-3" v-p-tooltip.top="'Undo'" @click="removeAcceptedAnswer(reply)">
                <fluency-icon type="undo"></fluency-icon>
              </t-button-inline>
            </template>
          </div>
        </div>
      </div>

    </div>
  </t-collapse>
</template>
<script>
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import ForumContentInput from '@/components/common/forum/forumContentInput'
import ForumTopicOptions from '@/components/common/forum/forumTopicOptions'
import ForumVotes from '@/components/common/forum/forumVotes'
export default {
  name: 'ForumTopic',
  components: { ForumVotes, ForumTopicOptions, ForumContentInput, MadLibSelect },
  props: {
    topic: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      sortBy: 'oldestFirst',
      sortByOptions: ['votes', 'oldestFirst', 'newestFirst'],
      replying: false,
      newReplyMessage: '',
      severityMap: {
        Question: 'info',
        Irritation: 'info',
        Concern: 'warning',
        Critical: 'danger'
      }
    }
  },
  computed: {
    sortedReplies () {
      if (!this.topic?.replies) return []
      return [...this.topic.replies]
        .filter(r => !r.removed)
        .sort((a, b) => {
          if (this.sortBy === 'votes') {
            const aVotes = (a.upVotes || 0) - (a.downVotes || 0)
            const bVotes = (b.upVotes || 0) - (b.downVotes || 0)
            if (aVotes > bVotes) {
              return -1
            } else if (aVotes < bVotes) {
              return 1
            } else {
              return a.createdOn > b.createdOn ? -1 : 1
            }
          } else if (this.sortBy === 'oldestFirst') {
            return a.createdOn > b.createdOn ? 1 : -1
          } else {
            return a.createdOn > b.createdOn ? -1 : 1
          }
        })
    },
    userName () {
      return this.$store.getters.user?.userName
    },
    internalUser () {
      return this.$store.getters?.user?.internalUser
    },
    showAcceptLink () {
      return (this.topic.userName === this.userName ||
        this.internalUser) &&
        !this.topic.replies?.some(r => r.acceptedAnswer)
    },
    replyCount () {
      return this.topic?.replies?.length || 0
    },
    voteCount () {
      return (this.topic.upVotes || 0) - (this.topic.downVotes || 0)
    },
    subscribed () {
      return this.topic.watches?.find(w => w.userName === this.userName)
    },
    assigned () {
      return this.topic.watches?.find(w => w.internalClaim)
    },
    assignedToCurrentUser () {
      return this.assigned?.userName === this.userName ? this.assigned : undefined
    }
  },
  methods: {
    formatTime (time) {
      return this.$moment.utc(time).local().format('MMM DD, YYYY')
    },
    formatTimeMinutes (time) {
      return this.$moment.utc(time).local().format('MMMM Do YYYY, h:mm a')
    },
    async acceptReply (reply) {
      await this.$store.dispatch('forum/acceptReply', reply)
    },
    async submitReply () {
      const resp = await this.$store.dispatch('forum/submitReply', {
        topic: this.topic,
        reply: { message: this.newReplyMessage }
      })
      if (resp) {
        this.replying = false
        this.newReplyMessage = ''
      }
    },
    removeAcceptedAnswer (reply) {
      this.$store.dispatch('forum/submitReplyUndo', {
        topic: this.topic,
        reply: { ...reply, acceptedAnswer: false },
        undo: true
      })
    },
    showDeleteReply (reply) {
      return this.internalUser || reply.userName === this.userName
    },
    async deleteReply (reply) {
      const cfm = await this.$confirm('This will permanently remove this reply.')
      if (cfm) {
        this.$store.dispatch('forum/deleteReply', reply)
      }
    }
  },
  mounted () {
    if (this.$store.getters['forum/manualTopicId']) {
      this.visible = true
    }
  }
}
</script>

<style lang="scss">
.forum-topic-collapsible-card {
  z-index: unset !important;
}
</style>
