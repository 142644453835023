<template>
  <div class="row">
    <div class="col-3 border-right">
      <forum-search stacked show-search-btn search-on-channel-change></forum-search>
      <forum-common-topics class="mt-5"></forum-common-topics>
    </div>
    <div class="col-9">
      <fluency-loader v-if="loading" class="mt-5"></fluency-loader>
      <template v-else-if="authoring"><forum-create-topic show-btn></forum-create-topic></template>
      <template v-else>
        <div v-if="customGroup" class="d-flex align-items-center mb-4">
          <span class="mr-3">Showing Posts Referencing:</span>
          <h3>
            <p-chip class="d-flex align-items-center filter-badge"
                    removable
                    @remove="clearCustomGroup()">
              {{customGroup}}
            </p-chip>
          </h3>
        </div>
        <forum-paging v-else></forum-paging>
        <forum-topic v-for="topic in searchResults" :key="topic.forumTopicId" :topic="topic"></forum-topic>
      </template>
    </div>
  </div>
</template>

<script>
import ForumSearch from '@/components/common/forum/forumSearch'
import ForumTopic from '@/components/common/forum/forumTopic'
import ForumCreateTopic from '@/components/common/forum/forumCreatTopic'
import ForumPaging from '@/components/common/forum/forumPagingSort'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import ForumCommonTopics from '@/components/common/forum/forumCommonTopics'
export default {
  name: 'forumFull',
  components: { ForumCommonTopics, ForumPaging, ForumCreateTopic, ForumTopic, ForumSearch, FluencyLoader },
  methods: {
    clearCustomGroup () {
      this.$store.dispatch('forum/search')
    }
  },
  computed: {
    searchResults () {
      return (this.$store.getters['forum/searchResults'] || []).filter(r => !r.removed)
    },
    authoring () {
      return this.$store.getters['forum/authoring']
    },
    loading () {
      return this.$store.getters['forum/loading']
    },
    customGroup () {
      return this.$store.getters['forum/customGroup']
    }
  }
}
</script>
